import * as React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import {
  ContentContainer,
  DetailsContainer,
  StyledColumn,
  StyledColumnImage,
  StyledGrid,
  StyledHeading,
  StyledPostDate
} from "./style";
import SecondaryButton from "../../../components/Button/SecondaryButton";


const BlogGrid = () => {
  const { blog } = useStaticQuery(graphql`
    query {
      blog: allMdx(
        filter: {
          frontmatter: {
            template: {
              eq: "blog"
            }
            draft: {
              eq: false
            }
          }
        }
        sort: {
          fields: [frontmatter___postDate]
          order: DESC
        }
      ) {
        edges {
          node {
            frontmatter {
              title
              postDate(formatString: "MMMM DD, YYYY")
              gridImage {
                childImageSharp {
                  gatsbyImageData(
                    width: 800
                    quality: 100
                    placeholder: TRACED_SVG
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `);
  
  return (
    <StyledGrid>
      { blog.edges.map((node) => {
        return (
          <StyledColumn>
            <Link
              to={ node.node.fields.slug }
            >
              <StyledColumnImage
                image={ getImage(node.node.frontmatter.gridImage) }
              />
            </Link>
            <ContentContainer>
              <DetailsContainer>
                <StyledPostDate>{ node.node.frontmatter.postDate }</StyledPostDate>
                <StyledHeading>{ node.node.frontmatter.title }</StyledHeading>
              </DetailsContainer>
              <SecondaryButton
                linkLocation={ node.node.fields.slug }
                linkText="Read More"
              />
            </ContentContainer>
          </StyledColumn>
        )
      })}
    </StyledGrid>
  )
}


export default BlogGrid;
