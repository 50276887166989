import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Section from "../components/Section";

import CallToAction from "../components/CallToAction";
import Carousel from "../components/Carousel";
import Grid from "../components/Grid";
import Layout from "../components/Layout";
import PageHero from "../components/Hero/PageHero";
import SEO from "../components/SEO";
import Ticker from "../components/Ticker";

import SecondaryButton from "../components/Button/SecondaryButton";

import BlogGrid from "../domain/Blog/BlogGrid";


const BlogPage = () => {
  const data = useStaticQuery(graphql`
    query {
      portfolio: allMdx(
        filter: {
          frontmatter: {
            template: {
              eq: "portfolio"
            }
          }
        }
      ) {
        edges {
          node {
            frontmatter {
              title
              homeCard {
                childImageSharp {
                  gatsbyImageData(
                    width: 1200
                    quality: 70
                    placeholder: TRACED_SVG
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="Blog" />
      
      <PageHero
        pageTitle="Blog"
        pageDescription=""
        Component={
          <Grid
            Component={
              <BlogGrid />
            }
          />
        }
      />

      <Section
        sectionTitle="Ready To Explore? Let's Talk."
        textAlignment="center"
        darkBackground
        Component={
          <CallToAction
            darkBackground
            ButtonComponent={
              <SecondaryButton
                linkLocation="/contact"
                linkText="Start A Project"
                darkBackground
              />
            }
          />
        }
      />

      <Section
        sectionTitle="Our Partners"
        sectionDescription="We have had the pleasure of working with small and large organizations in all industries to help redefine their digital marketing efforts."
        isComponentFullLength
        Component={
          <Ticker
            template="portfolio"
            itemWidth={ 10 }
          />
        }
      />

      <Section
        sectionTitle="Our Work"
        sectionDescription="We have had the pleasure of working with small and large organizations in all industries to help redefine their digital marketing efforts."
        isComponentFullLength
        Component={
          <Carousel
            layout="portfolio"
            nodes={ data.portfolio.edges }
            service="home"
            linkLocation="/portfolio"
            linkText="Explore More"
            options={
              {
                loop: true,
              }
            }
          />
        }
      />
    </Layout>
  )
}


export default BlogPage;
