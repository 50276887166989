import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";

import {
  COLORS,
  SCREENS
} from "../../../theme/style-constants";


export const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 4rem 2rem;

  @media (min-width: ${ SCREENS.tablet }) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: ${ SCREENS.laptop }) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const StyledColumn = styled.div`
  border-bottom: 1px solid ${ COLORS.border.dark };
`;

export const StyledColumnImage = styled(GatsbyImage)`

`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 12rem;
  margin: 0 0 2rem;
`;

export const DetailsContainer = styled.div`

`;

export const StyledPostDate = styled.p`
  margin: 1rem 0;
  font-size: 0.9rem;
  opacity: 0.8;
`;

export const StyledHeading = styled.h3`
  margin: 0 0 1rem;
`;
